import React from 'react'
import { Link } from 'gatsby'
import '../styles/global.css'
import { useIntl } from "gatsby-plugin-intl"
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTelegram,
        faInstagram,
        faLinkedin,
        faWhatsapp,
        faFacebook,
        faYoutube,
    } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope,
            faPhone, 
            faMobile, 
            faLocationDot, 
            faClock, 
            faLanguage} 
        from '@fortawesome/free-solid-svg-icons'
import Seo from '../components/seo'

const ContactPage = () => {
    const intl = useIntl();
    const mapData = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380.9719301378507!2d44.50916879060232!3d40.19181066028801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abdb015b0fab7%3A0xf324aff6ebe6944d!2sGritarres!5e0!3m2!1sen!2s!4v1649843934547!5m2!1sen!2s" height="450" style="border:0; width:100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    return (          
        <Layout 
        isHome={false}>
                <div className='container'> 
                    <section className="post-feed">   
                        
                        <Link to='tel: +37498476003' className="contact-card">
                            <header className="post-card-header">
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faPhone} />
                            </header>
                            <section>
                                <h2 className="post-card-title">{intl.formatMessage({id:"Phone"})}</h2>
                                <h2 className="post-card-excerpt">+37498476003</h2>
                            </section>
                        </Link>
                        
                        <Link to='mailto:info@gritarres.com' className="contact-card">
                            <header className="post-card-header">
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faEnvelope} />
                            </header>
                            <section >
                                <h2 className="post-card-title">{intl.formatMessage({id:"Email"})}</h2>
                                <h2 className="post-card-excerpt">info@gritarres.com</h2>
                            </section>
                        </Link>

                        <Link to='tel: +37498476003' className="contact-card">
                            <header className="post-card-header">
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faMobile} />
                            </header>
                            <section >
                                <h2 className="post-card-title">{intl.formatMessage({id:"Cell Phone"})}</h2>
                                <h2 className="post-card-excerpt">+37498476003</h2>
                            </section>
                        </Link>

                        <a 
                            href='https://www.google.com/maps/dir//Gritarres,+Yerevan+Arshakunyats+Ave.,+65%2F5+Expocore+complex,+2nd+Floor,+Yerevan+0061,+Armenia/@40.1446421,44.4914005,15.75z/data=!4m8!4m7!1m0!1m5!1m1!1s0x406abdb015b0fab7:0xf324aff6ebe6944d!2m2!1d44.4974146!2d40.1440267?hl=en&entry=ttu' 
                            className="contact-card"
                            target='_blank'
                            rel='noreferrer'
                        >
                            <header className="post-card-header">
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faLocationDot} />
                            </header>
                            <section>
                            <h2 className="post-card-title">{intl.formatMessage({id:"Address"})}</h2>
                            <p className="post-card-excerpt">{intl.formatMessage({id:"Armenia, 0061, Yerevan, Arshakunyats Ave., 65/5, Expocore Complex, 2nd Floor"})}</p>
                            </section>
                        </a>

                        <div className="contact-card">
                            <header className="post-card-header">
                                <FontAwesomeIcon 
                                size='3x'
                                icon={faClock} />
                            </header>
                            <section >
                            <h2 className="post-card-title">{intl.formatMessage({id:"Working Hours"})}</h2>
                            <h2 className="post-card-excerpt">{intl.formatMessage({id:"Monday-Friday 10:00-20:00"})}</h2>
                            </section>
                        </div>
                        <div className="contact-card">
                            <header className="post-card-header">
                                <FontAwesomeIcon 
                                size='3x'
                                icon={faLanguage} />
                            </header>
                            <section >
                                <h2 className="post-card-title">{intl.formatMessage({id:"Languages"})}</h2>
                                <p className="post-card-excerpt">{intl.formatMessage({id:"Armenian, Russian, English, Persian, French"})}</p>
                            </section>
                        </div>
                    </section>
                        <div className='social-box'>
                            <a 
                            href='https://wa.me/98476003' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon
                                size='3x'
                                icon={faWhatsapp} />
                            </a>
                            <a 
                            href='https://t.me/Gritarres' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon
                                size='3x'
                                icon={faTelegram} />
                            </a>

                            <a
                            href='https://www.facebook.com/Gritarres/' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon 
                                size='3x'
                                icon={faFacebook} />
                            </a>
                            <a 
                            href='https://www.instagram.com/gritarreslawfirm/' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon 
                                size='3x'
                                icon={faInstagram} />
                            </a>
                            <a 
                            href='https://www.linkedin.com/company/gritarres-professional-law-firm/' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faLinkedin} />
                            </a>
                            <a 
                            href='https://www.youtube.com/@Gritarres' 
                            className="social-icon"
                            target='_blank'
                            rel='noreferrer'
                            >
                                <FontAwesomeIcon
                                size='3x' 
                                icon={faYoutube} />
                            </a>
                        </div>
                        <div className='map-container'>
                            <div
                            dangerouslySetInnerHTML={{__html: mapData}}/>
                            <ContactForm/>
                        </div>              
                        
                </div> 
        </Layout>  
    )
}

export const Head = ({ pageContext }) => (
    <Seo
    title={`${pageContext.intl.messages["Contact"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
    description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
    article={false}>
    </Seo>
  )

export default ContactPage
