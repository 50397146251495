// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import '../styles/global.css';
import { useIntl } from "gatsby-plugin-intl";



function ContactForm() {
  const intl = useIntl();
  const [state, handleSubmit] = useForm("meqwzege");
  if (state.succeeded) {
      return <p>Thanks for Your Message, We'll Contact You Shortly!</p>;
  }
  return (
      <form onSubmit={handleSubmit}>
      <h1>{intl.formatMessage({id: "Contact Form" })}</h1>
      <label htmlFor="name">
        {intl.formatMessage({id: "Name *" })}
      </label>
      <input
        id="name"
        type="text" 
        name="name"
        className='contact_form_input'
        placeholder={intl.formatMessage({id: "Please Enter Your Name" })}
      />
      <ValidationError 
        prefix="Your Name:" 
        field="name"
        errors={state.errors}
      />
      <label htmlFor="email">
        {intl.formatMessage({id: "Email Address *" })}
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        className='contact_form_input'
        placeholder={intl.formatMessage({id: "Please Enter Your Email Address" })}
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <label htmlFor="email">
        {intl.formatMessage({id: "Your Message *" })}
      </label>
      <textarea
        id="message"
        name="message"
        className='contact_form_input'
        placeholder={intl.formatMessage({id: "Please Enter Your Message" })}
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        {intl.formatMessage({id: "Submit" })}
      </button>
    </form>
  );
}

export default ContactForm;